<template>
  <div class="page">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>当前位置</el-breadcrumb-item>
        <el-breadcrumb-item>服务</el-breadcrumb-item>
        <el-breadcrumb-item>会员服务</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-carousel height="472px">
      <el-carousel-item v-for="(item,i) in banner" :key="i">
        <el-image :src="item.img" class="banner" />
      </el-carousel-item>
    </el-carousel>
    <h1 class="title">{{ info.name }}</h1>
    <div class="price-box">
      <span class="price-label">价格</span>
      <span class="price">￥{{ info.current_price }}</span>
      <span class="o-price">原价：￥{{ info.original_price }}</span>
    </div>
    <p class="leixing">
      <span class="label">类型</span>
      <span class="type">{{ info.name }}</span>
    </p>
    <div class="buy" @click="show=true">立即购买</div>
    <div class="rule">
      <p>1.开通VIP后畅享查、测、填、学、问5大核心功能</p>
      <p>2.适用对象：普通文理科考生</p>
      <p>3.使用时效：有效期截止到用户自行选择的高考年9月1日为止</p>
    </div>
    <el-dialog :visible.sync="show" title="请输入您的个人信息" width="30%">
      <el-input v-model="mobile" placeholder="请输入有效手机号码" />
      <p class="pay-title">选择支付类型</p>
      <div class="pay-box">
        <div @click="pay_type = 1">
          <p>
            <i class="iconfont icon-zhifubao" style="color: #18ADE7" />
          </p>
          <el-radio v-model="pay_type" :label="1">支付宝</el-radio>
        </div>
        <div @click="pay_type = 2">
          <p>
            <i class="iconfont icon-weixin" style="color: #10CD19" />
          </p>

          <el-radio v-model="pay_type" :label="2">微信</el-radio>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <span class="pay-btn" @click="pay">确认支付</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Vip",
  data() {
    return {
      mobile: "",
      pay_type: 1,
      show: false,
      banner: [],
      info: {},
    };
  },

  mounted() {
    this.$fecth
      .post("goods/getGoodsInfo", {
        id: 2,
      })
      .then((res) => {
        this.info = res;
      });
    this.$fecth
      .post("banner/getData", {
        type: 1,
      })
      .then((res) => {
        if (res.lists.length > 0) this.banner = res.lists;
      });
  },
  methods: {
    pay() {
      if (this.mobile == "") {
        this.$message.error("请输入手机号");
        return;
      }
      if (this.pay_type == 1) {
        let payurl =
          "https://bkgaoshou.com/index.php/api/order/getOrder?goods_id=" +
          this.info.id +
          "&mobile=" +
          this.mobile +
          "&type=1" +
          "&user_id=" +
          this.$store.state.userInfo.id;
        window.open(payurl);
      } else {
        this.$message.error("暂未开通微信支付");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {

  .banner {
    width: 100%;
    height: 472px;
  }

  .title {
    font-size: 40px;
    margin: 30px 0;
  }

  .price-box {
    background-color: #fdeadc;
    padding: 40px 30px;

    .price-label {
      font-size: 30px;
      font-weight: bold;
      color: #958c87;
      margin-right: 10px;
    }

    .price {
      color: #d60003;
      font-weight: bold;
      font-size: 40px;
      margin-right: 20px;
    }
    .o-price {
      font-size: 28px;
      color: #958c87;
      text-decoration: line-through;
    }
  }

  .leixing {
    font-size: 30px;
    margin: 30px 0;

    .label {
      margin-right: 20px;
      font-weight: bold;
      color: #666666;
    }

    .type {
      border-radius: 6px;
      font-size: 24px;
      color: #fbbc87;
      border: 1px solid #fbbc87;
      padding: 10px 30px;
    }
  }

  .buy {
    margin: 30px auto;
    width: 600px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    background-image: linear-gradient(to right, #f59392, #f8c399);
    color: #ffffff;
    border-radius: 10px;
    font-size: 26px;
  }
  .rule {
    font-size: 18px;
    font-weight: bold;
    color: #575757;
    p {
      margin-bottom: 6px;
    }
  }
  .pay-title {
    margin: 10px 0;
  }
  .pay-box {
    cursor: pointer;
    text-align: center;
    > div:first-child {
      margin-right: 30px;
    }
    > div {
      display: inline-block;
      width: 100px;
      .iconfont {
        font-size: 80px;
      }
    }
  }
  .dialog-footer {
    text-align: center;
    .pay-btn {
      background-image: linear-gradient(to right, #f59392, #f8c399);
      color: #ffffff;
      display: inline-block;
      width: 180px;
      height: 60px;
      line-height: 60px;
      font-size: 22px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
</style>
